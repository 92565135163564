	//时间转化
	export function getLocalTime(nS, fmt) {
		if(nS){
			var date = new Date(nS * 1000);
			return setFormmatFun(date, fmt)
		}else{
			return ''
		}
	}
	export function setFormmatFun(date = new Date(), fmt = 'yyyy-MM-dd') {
	    date = setDateType(date);
	    var o = {
	        "M+": date.getMonth() + 1, //月份
	        "d+": date.getDate(), //日
	        "h+": date.getHours(), //小时
	        "m+": date.getMinutes(), //分
	        "s+": date.getSeconds(), //秒
	        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
	        "S": date.getMilliseconds(), //毫秒
	        "W": "日一二三四五六".charAt(date.getDay()), //星期
	    };
	    if (/(y+)/.test(fmt))
	        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	    for (var k in o)
	        if (new RegExp("(" + k + ")").test(fmt))
	            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
	    return fmt;
    }
    function setDateType(date){
    return typeof date === 'string' ? new Date(date.replace(/\-/g, '/')) : date;
	}
	
	//实现数字增长效果
	export function timedCountFunc(el, nums, totalNum, timer=1, isSetNumber=false, c) {
		var count = totalNum>1000? Math.round(totalNum / 700):1;
		if(c) count = Math.round(totalNum / 500);
		nums = nums + count;
		el.innerHTML = nums
		// 设置条件使停止计时
		if (nums < totalNum) {
			setTimeout(() => { timedCountFunc(el, nums, totalNum, timer, isSetNumber, c) }, timer);
		} else {
			el.innerHTML = isSetNumber? (setNumberString(totalNum)) : totalNum
		}
	}
	export function setNumberString(number){
		let str = number+'',
			reg=null;
		if(str.indexOf(".")==-1){
			reg = /(?=(?!(\b))(\d{3})+$)/g
		}else{
			reg =/(\d{1,3})(?=(\d{3})+(?:\.))/g;
		}
		str=str.replace(reg, "$1,");
		return str
	}

	export function getListByObj(obj=[]){
		if(obj instanceof Array){
			return obj
		}else if( obj instanceof Object ){
			let list = [];
			for(let i in obj){
				list.push(obj[i])
			}
			return list
		}else{
			return []
		}
	}
	export function initNes(obj, str){
		if(!obj)return null;
		let result = {},
			list = [];
		if(obj.name!=undefined){
			result['name'] = obj.name;
			delete obj.name;
		}
		
		if(str&&obj[str]){
			result[str] = obj[str];
			delete obj[str];
		}
		if(obj.category_id!=undefined){
			result['category_id'] = obj.category_id;
			delete obj.category_id;
		}
		for(let i in obj){
			let item = obj[i];
			list.push(item)
		}
		result['val'] = list;
		return result
	}