import scrollReveal from "scrollreveal";
export default {
    install(Vue) {
        Vue.mixin({
            data(){
                return{
                    scrollReveal: scrollReveal(),
                }
            },
            mounted() {
                // this.setScrol(); 
            },
            methods:{
                setScrol() {
                    let isWeb = this.$store.state.bodyWidth>this.$webMinWidth;
                    this.scrollReveal.reveal(".src-top", {
                        duration: 1500,
                        delay: 0,
                        origin: "top",
                        reset: false,
                        mobile: true,
                        distance: "80px",
                        opacity: 0.5,
                        easing: "ease"
                    });
                    this.scrollReveal.reveal(".src-bottom", {
                        duration: 1500,
                        delay: 0,
                        reset: false,
                        mobile: true,
                        opacity: 0.5,
                        distance: "80px",
                        easing: "ease",
                        origin: "bottom",
                    });
                    this.scrollReveal.reveal(".src-right", {
                        duration: 1000,
                        delay: 0,
                        reset: false,
                        mobile: true,
                        opacity: 0.5,
                        distance: "100px",
                        easing: "ease",
                        origin: "right"
                    });
                    this.scrollReveal.reveal(".src-left", {
                        duration: 1000,
                        delay: 0,
                        reset: false,
                        mobile: true,
                        opacity: 0.5,
                        distance: "100px",
                        easing: "ease",
                        origin: "left"
                    });
                    this.scrollReveal.reveal(".src-top-1", {
                        duration: 1000,
                        delay: 0,
                        origin: "top",
                        reset: false,
                        mobile: true,
                        distance: "200px",
                        opacity: 0.5,
                        easing: "ease-in"
                    });
                    this.scrollReveal.reveal(".src-bottom-1", {
                        duration: 1000,
                        delay: 0,
                        reset: false,
                        mobile: true,
                        opacity: 0.5,
                        distance: "200px",
                        easing: "ease-in",
                        origin: "bottom",
                    });
                    this.scrollReveal.reveal(".src-right-1", {
                        duration: 1000,
                        delay: 0,
                        reset: false,
                        mobile: true,
                        opacity: 0.5,
                        distance: "600px",
                        easing: "ease-in",
                        origin: "right"
                    });
                    this.scrollReveal.reveal(".src-left-1", {
                        duration: 1000,
                        delay: 0,
                        reset: false,
                        mobile: true,
                        opacity: 0.5,
                        distance: "600px",
                        easing: "ease-in",
                        origin: "left"
                    });
                },
            }
        })
    }
  
}