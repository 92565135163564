<template>
    <div class="footer_box">
        <div class="content">
            <div class="left_">
                <div class="route_list" v-if="footerInfo.nav">
                    <div v-for="(item,index) in footerInfo.nav" :key="item.id">
                        <span @click="goRoute(item.href)">{{item.name}}</span>
                        <span  class="li_" :style="{opacity:index==footerInfo.nav.length-1?0:1}">|</span>
                    </div>
                </div>

                <div class="display_">
                    <div class="info_tap">
                        易宝产发集团总部：{{footerInfo.address}} 
                    </div>
                    <div class="info_tap">
                        联系电话：{{footerInfo.phone}} 
                    </div>
                    <div class="info_tap">
                        联系邮箱：{{footerInfo.email}} 
                    </div>
                </div>
                <div class="display_" v-if="footerInfo.headquarters">
                    <div class="info_tap">
                        易宝支付总部：{{footerInfo.headquarters.address}} 
                    </div>
                    <div class="info_tap">
                        联系电话：{{footerInfo.headquarters.phone}} 
                    </div>
                    <div class="info_tap">
                        联系邮箱：{{footerInfo.headquarters.email}} 
                    </div>
                </div>
                
            </div>
            <div class="riht_">
                <img :src="footerInfo.erCode" >
            </div>
        </div>
        <div class="line"></div>
        <div class="bottom_">
            <div>Copyright {{footerInfo.copy}} 版权所有</div>
            <div>{{footerInfo.icp}} </div>    
        </div>
    </div>
</template>
<script>
export default {
    computed:{
        footerInfo:{
            get(){
                return this.$store.state.footer||{}
            },
        },
    },
    methods:{
        goRoute(href){
            let timeStr = new Date().getTime();
            let o = {t_: timeStr};
            let first = href.split('?')[0],
                second = href.split('?')[1]
            if(second){
                second.split('&').forEach(item=>{
                    let n = item.split('=')
                    o[n[0]] = n[1]
                })
            }
            let name = first.split('#')[0]
            this.$router.push({name:name, query:o}) 
        }
    }
}
</script>
<style lang="scss" scoped>
.footer_box{
    width: 1200px;
    margin: 0 auto;
    padding-top: 63px;
    height: 314px;
    position: relative;
    text-align: left;
    .content{
        color: #fff;
        display: inline-block;
        width: 100%;
        .left_{
            float: left;
            width: calc(100% - 130px);
        }
        .riht_{
            float: right;
            &>img{
                width: 124px;
                height: 124px;
            }
        }
        .route_list{
            font-size: 14px;
            color: #FFFFFF;
            line-height: 21px;
            >div{
                display: inline-block;
                vertical-align: middle;
            }
            .li_{
                padding: 0 12px;
            }
            span:not(.li_){
                cursor: pointer;
                position: relative;
                &:hover{
                    text-decoration: underline;
                    color: $lv;
                    // &::after{
                    //     content: '';
                    //     position: absolute;
                    //     left: 0;
                    //     right: 0;
                    //     bottom: 0;
                    //     height: 1px;
                    //     background-color: $lv;
                    // }
                }
            }
        }
        .display_{
            display: inline-block;
            width: 50%;
            vertical-align: top;
        }
        .info_tap{
            margin-top: 14px;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 21px;
            opacity: 0.6;
        }
    }
    .line{
        margin-top: 30px;
        width: 100%;
        height: 1px;
        background: rgba($color: #fff, $alpha: 0.4);
    }
    .bottom_{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
        text-align: center;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 21px;
        opacity: 0.4;
    }
}
</style>