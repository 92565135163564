import Vue from 'vue'
import Vuex from 'vuex'
import Request from '@/util/request'

Vue.use(Vuex)
export default new Vuex.Store({
    state:{
        bodyWidth:0,
        footer:null,
        pageScrollTop:0,
    },
    getter:{

    },
    actions:{
        getFooterAction:function({dispatch,commit}){
            return Request({
                url: '/portal/footer',
                method: 'get',
            }).then(({data})=>{
                data = data||{};
                data.erCode = data.banners&&data.banners[0]&&data.banners[0].image;
                commit('setFooter', data);
            })
        }
    },
    mutations:{
        setBodyWidth:(state, data) => {
            state.bodyWidth = data;
        },
        setFooter:(state, data) => {
            state.footer = data;
        },
        setPageScrollTop:(state, data) => {
            state.pageScrollTop = data;
        },
    }
})