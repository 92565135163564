import Vue from 'vue'
import Router from 'vue-router'
import Config from '@/config';
import Store from '@/store';


Vue.use(Router)
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};

const router = new Router({
    // mode: 'history',
    base: Config.BASE_PATH,
    routes: [
        {
          path: '/',
          name: 'home',
          component: resolve => require(['@/views/home'], resolve),
          meta: {title: '首页'},
        },{
          path: '/aboutUs',
          name: 'aboutUs',
          component: resolve => require(['@/views/aboutUs'], resolve),
          meta: {title: '关于我们'},
        },{
          path: '/cityAbility',
          name: 'cityAbility',
          component: resolve => require(['@/views/cityAbility'], resolve),
          meta: {title: '城市赋能'},
        },{
          path: '/infomation',
          name: 'infomation',
          component: resolve => require(['@/views/infomation'], resolve),
          meta: {title: '新闻资讯'},
        },{
          path: '/infomationList',
          name: 'infomationList',
          component: resolve => require(['@/views/infomation-list'], resolve),
          meta: {title: '资讯列表'},
        },{
          path: '/infomationDetail',
          name: 'infomationDetail',
          component: resolve => require(['@/views/infomation-detail'], resolve),
          meta: {title: '资讯详情'},
        },{
          path: '/socialReponse',
          name: 'socialReponse',
          component: resolve => require(['@/views/socialReponse'], resolve),
          meta: {title: '社会责任'},
        },{
          path: '/contactUs',
          name: 'contactUs',
          component: resolve => require(['@/views/contactUs'], resolve),
          meta: {title: '联系我们'},
        },
    ]
})

router.beforeEach((to, from, next) => {
  window.scroll(0, 0);
  var footer = Store.state.footer;
  if(footer){
    next()
  }else{
    Store.dispatch('getFooterAction').finally(()=>{
      next()
    })
  }
  
})
export default router