// rem等比适配配置文件
// 基准大小
const baseSize = 10
// 设置 rem 函数
function setRem() {
    // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
    // const scale = document.documentElement.clientWidth / 750
    // // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
    // const fontSizeVal = baseSize * Math.min(scale, 1);
    // document.documentElement.style.fontSize = (fontSizeVal>6?fontSizeVal:6)+ 'px'
    document.documentElement.style.fontSize = baseSize+ 'px'
}
export default setRem