<template>
  <div id="app" class="pc">
    <header-component></header-component>
    <router-view/>
  </div>
</template>

<script>

import headerComponent from '@/components/header'
export default {
  name: 'App',
  components:{headerComponent}
}
</script>

<style>
</style>
