import axios from 'axios'
import Config from '@/config';

const CancelToken = axios.CancelToken;

// 数据请求
const Request = function(param){
    if (param.url) {
        param = Object.assign({
          data: {},
          method: 'post',
          prefix: Config.BASE_URL,
          header: {},
          dataType: 'json',
          responseType: 'text'
        }, param);
        param.url = new RegExp('^http(s)?://', 'i').test(param.url) ? param.url : param.prefix + param.url;
        let abort = function () {};
        const promise = new Promise(function (resolve, reject) {
            axios({
                url: param.url,
                method: param.method,
                params: param.method === 'get' ? param.data : undefined,
                data: param.method === 'post' ? param.data : undefined,
                headers: (() => {
                    return Object.assign( {'Content-Type': 'application/json; charset=utf-8'}, param.header);
                })(),
                responseType: param.responseType,
                cancelToken: new CancelToken(function executor(cancel) {
                    abort = function () { cancel() };
                })
            }).then((response) => {
                if (response.status === 200) {
                    let data = response.data;
                    // data.status = parseInt(data.status);
                    resolve(data);
                } else {
                    console.error('Request error', arguments, url, data);
                    throw new Error('Request error');
                }
            }).catch(({config, request, response}) => {
                reject()
            });
        });
        promise.abort = abort;
        // promise.catch(function () { Wx.showToast('网络加载失败，请稍后再试！'); });
        return promise;
    }
    console.error('Request param URL can\'t be empty.');
    throw new Error('Request param URL can\'t be empty.');
}

export default Request;