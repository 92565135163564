const isProduct = process.env.NODE_ENV === 'production'; // 正式版本
const isLocal = process.env.NODE_ENV === 'development'; // 本地开发

let property = require('../property.json');

const Config = {
    VERSION: property.VERSION,
    BASE_PATH: (() => {
        let path = isProduct ? property.BASE_PATH : '';
        console.log('[DEBUG] 当前开发模式：线上版本[ %s ] 本地测试环境[ %s ]', isProduct, isLocal);
        console.log('[DEBUG] 当前BASE_PATH [ %s ]', path);
        console.log('process.env.NODE_ENV==' + process.env.NODE_ENV);
        return path;
    })(),
    BASE_URL: (() => {
        let path = isProduct ? property.PRODUCT_PATH : property.DEVELOP_PATH;
        console.log('[DEBUG] 当前Request_BASEURL [ %s ]', path);
        return path;
    })(),
    PREFIX: (() => {
        return property.PREFIX_IMG;
    })(),
    DOCUMENT_TITLE: (() => {
        return property.DOCUMENT_TITLE;
    })(),
}

export default Config;