import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
import store from '@/store/index'

import '@/element-ui'
import '@/element-ui-theme/index.css'
import setRem from '@/util/rem'
import '@/assets/css/index.scss'
import footerComponent from '@/components/footer'
Vue.component('footerComponent', footerComponent)
import Request from '@/util/request'
import {getLocalTime, getListByObj, initNes} from '@/util/index'
import scrollReveal from '@/mixins/scrollReveal.mixins';
Vue.use(scrollReveal);

import Swiper from "swiper"
import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false
Vue.prototype.$Request = Request;
Vue.prototype.$getListByObj = getListByObj;
Vue.prototype.$initNes = initNes;

Vue.filter('edit_content',function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace( /<[^<>]+>/g, '')
})
Vue.filter('set_time',function (nS, fmt) {
  return getLocalTime(nS, fmt)
})

let setClienWh = ()=>{
  let width = window.innerWidth;
  store.commit('setBodyWidth', width)
  setRem();
}
setClienWh();
window.onresize = function () {
  setClienWh();
}


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
