<template>
    <div class="page_footer">
        <footer-box></footer-box>
    </div>
</template>
<script>
import footerBox from "./footer-box";
export default {
    components:{footerBox}
}
</script>
<style lang="scss" scoped>
.page_footer{
    background: #000000;
}
</style>