import Vue from 'vue'
import {
    Pagination,
    Dialog,
    Input,
    Select,
    Option,
    OptionGroup,
    Button,
    Table,
    TableColumn,
    DatePicker,
    Form,
    FormItem,
    Alert,
    Loading,
    MessageBox,
    Message,
}from 'element-ui'

Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Alert)


Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$message = Message
Vue.prototype.$ELEMENT = { size: 'medium', zIndex:3000 }