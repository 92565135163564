<template>
    <div class="page-header" :class="isMask?'s-mask':''">
        <div :class="currentRoute">
            <img v-if="currentRoute=='home'||currentRoute=='infomationDetail'" @click="goHome" :src="logo" class="logo">
            <img v-else :src="isMask?logo:logo_w" class="logo" @click="goHome">
            <div class="menuList">
                <div v-for="item in menu" :key="item.val" :class="{'active' : currentRoute==item.val}" @click="goRouter(item.val)">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            menu:[
                {name:'首页', val:'home'},
                {name:'关于我们', val:'aboutUs'},
                {name:'城市赋能', val:'cityAbility'},
                {name:'新闻资讯', val:'infomation'},
                {name:'社会责任', val:'socialReponse'},
                {name:'联系我们', val:'contactUs'},
            ],
            currentRoute:'',
            isMask: false,
            logo: require('@/assets/img/logo.jpg'),
            logo_w: require('@/assets/img/logo_w.png'),
        }
    },
    watch:{
        '$route.name':{
            handler(val){
                this.currentRoute = val||'home';
            }
        },
    },
    created(){
        window.onscroll =()=>{
            var scrollTop = document.documentElement.scrollTop;
            this.$store.commit('setPageScrollTop',scrollTop)
            if (scrollTop > 90) {
                this.isMask = true
            }else{
                this.isMask = false
            }
        }
    },
    methods:{
        goRouter(name){
            if(name!=this.currentRoute){
                this.$router.push({name: name})
            }else{
                this.$router.push({name: name, query:{t: new Date().getTime()}})
            }
        },
        goHome(){
            this.$router.push({name: 'home', query:{t: new Date().getTime()}})
        }
    }
}
</script>
<style lang="scss" scoped>
.page-header{
    position: fixed;
    z-index: 110;
    left: 0;
    top: 0;
    width: 100%;
    height: 70px;
    transition: all .2s ease;
    &>div{
        width: 1200px;
        margin: 0 auto;
        color: #333333;
    }
    .logo{
       display: block;
       width: 126px;
       height: 50px; 
       margin-top: 10px;
       float: left;
       cursor: pointer;
    }
    .menuList{
        float: right;
        padding-top: 10px;
        font-size: 16px;
        &>div{
            cursor: pointer;
            display: inline-block;
            width: 130px;
            text-align: center;
            position: relative;
            line-height: 45px;
            &.active{
                color: $lv;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -15px;
                    width: 100%;
                    height: 4px;
                    background-color: $lv;
                }
            }
        }
    }
    &.s-mask{
        background: #fff;
        box-shadow: 0 2px 15px 0 rgba($color: $lv, $alpha: 0.15);
        .menuList>div:hover{
            color: $lv;
        }
    }
    &:not(.s-mask){
        .menuList>div{
            &.active{
                color: #fff;
                &::after{
                    background-color: #fff;
                }
            }
            &:hover{
                color: #fff;
            }
        } 
        
        .home,.infomationDetail{
           .menuList>div{
                &.active{
                    color: $lv;
                    &::after{
                        background:$lv;
                    }
                }
                &:hover{
                    color:$lv;
                }
           }
        }
    }

}
</style>